import React from 'react';
import './intro.css';
import btnImg from '../../assets/hireme.png';
import { Link } from 'react-scroll';
import { Helmet } from 'react-helmet';
const Intro = () => {
    <Helmet>
      <title>Home -balakarthikeyan</title>
      <meta name="description" content="Welcome to the portfolio of Your Name. Explore my projects, skills, and experience in the world of data analysis." />
      <meta name="keywords" content="portfolio, data analysis, Your Name, projects, skills, experience" />
      <meta name="author" content="bala" />
      <meta property="og:title" content="Home - Your Name's Portfolio" />
      <meta property="og:description" content="Welcome to the portfolio of Your Name. Explore my projects, skills, and experience in the world of data analysis." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.yourportfolio.com" />
      <meta property="og:image" content="https://www.yourportfolio.com/images/home-image.jpg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Home - Your Name's Portfolio" />
      <meta name="twitter:description" content="Welcome to the portfolio of Your Name. Explore my projects, skills, and experience in the world of data analysis." />
      <meta name="twitter:image" content="https://www.yourportfolio.com/images/home-image.jpg" />
    </Helmet>
   
    return (
        
        <section id="intro">
            <div className="introContent">
                <span className="hello">Hello,</span>
                <span className="introText">I'm <span className="introName">BALA</span> <br />DATA ANALYST</span>
                <p className="introPara">I am a data analyst with a knack for transforming ,complex data into actionable insights,<br/>driving business decisions, and optimizing performance.






</p>
                <Link><button className="btn"><img src={btnImg} alt="Hire" className='btnImg'/> Hire Me</button></Link>
            </div>
            {/* <img src={bg} alt="Profile" className="bg" /> */}
        </section>
    );
}

export default Intro;