import React from 'react';
import './skills.css';
import Python from '../../assets/python.png';
import Excel from '../../assets/excel.png';
import Vv from '../../assets/vv.png';
import { Helmet } from 'react-helmet';

const Skills = () => {
    <Helmet>
    <title>About Us -Balakarthikeyan</title>
    <meta name="description" content="Learn more about Your Company Name, our mission, balakarthikeyan.me,vision, and the team that makes it all possible." />
    <meta name="keywords" content="About,balakarthikeyan, mission, vision, team" />
    <meta name="author" content="Your balakarthikeyan Name" />
    <meta property="og:title" content="About Us - balakarthikeyan" />
    <meta property="og:description" content="Learn more about Your Company Name, our mission, vision, and the team that makes it all possible." />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.yourwebsite.com/about" />
    <meta property="og:image" content="https://www.yourwebsite.com/images/about-image.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="About Us - balakarthikeyan" />
    <meta name="twitter:description" content="Learn more about Your Company Name, our mission, vision, and the team that makes it all possible." />
    <meta name="twitter:image" content="https://www.yourwebsite.com/images/about-image.jpg" />
  </Helmet>
    return (
        <section id='skills'>
            <span className="skillTitle">What I do</span>
            <span className="skillDesc">I am a skilled and passionate data analyst with a strong understanding of design and experience in creating visually appealing and user-friendly dashboards. I am proficient in Python, Power BI, Excel, R programming, MySQL, NumPy, pandas, matplotlib, and Tableau..</span>
            <div className="skillBars">
                <div className="skillBar">
                    <img src={Python} alt="PYTHON" className="skillBarImg" />
                    <div className="skillBarText">
                        <h2>PYTHON/R PROGRAMING</h2>
                        <p>Python, along with libraries like NumPy and pandas, allows for efficient handling of large datasets and complex data operations.</p>
                    </div>
                </div>
                <div className="skillBar">
                    <img src={Vv} alt="POWERBI" className="skillBarImg" />
                    <div className="skillBarText">
                        <h2>POWER BI/TABLEAU</h2>
                        <p>Crucial for data visualization and reporting. These tools enable the creation of interactive and insightful dashboards, helping to convey data-driven insights to stakeholders effectively..</p>
                    </div>
                </div>
                <div className="skillBar">
                    <img src={Excel} alt="EXCEL" className="skillBarImg" />
                    <div className="skillBarText">
                        <h2>EXCEL/GOOGLE SHEETS</h2>
                        <p>Fundamental for data analysis, quick calculations, and data visualization. Excel is widely used for its versatility, ease of use, and powerful features like pivot tables and advanced functions.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Skills;