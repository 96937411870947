import './contact.css';
import mysql from '../../assets/mysql.png';
import powerbi from '../../assets/powerbi.png';
import python from '../../assets/python.png';
import excel from '../../assets/excel.png';
import { Helmet } from 'react-helmet';

import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
const Contact = () => {

    <Helmet>
    <title>Contact Us - Your Name's Portfolio</title>
    <meta name="description" content="Get in touch with Your Name. Reach out for inquiries, collaborations, or any questions you may have." />
    <meta name="keywords" content="contact, Your Name, inquiries, collaborations, questions" />
    <meta name="author" content="Your Name" />
    <meta property="og:title" content="Contact Us - Your Name's Portfolio" />
    <meta property="og:description" content="Get in touch with Your Name. Reach out for inquiries, collaborations, or any questions you may have." />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.yourportfolio.com/contact" />
    <meta property="og:image" content="https://www.yourportfolio.com/images/contact-image.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Contact Us - Your Name's Portfolio" />
    <meta name="twitter:description" content="Get in touch with Your Name. Reach out for inquiries, collaborations, or any questions you may have." />
    <meta name="twitter:image" content="https://www.yourportfolio.com/images/contact-image.jpg" />
  </Helmet>
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_6phm4ar', 'template_zcxds5a', form.current, 'blaHt_5KIJ711DfGH')
            .then((result) => {
                console.log(result.text);
                e.target.reset();
                alert('Email Sent !');
            }, (error) => {
                console.log(error.text);
            });
    };
    
    return (
        <div id='contactPage'>
            <div id="clients">
                <h1 className="contactPageTitle">My SKILL</h1>
                <span className="clientDesc">
                    I have had the opportunity to work with a diverse group of companies. 
                    Some of the notable companies I have worked with includes
                </span>
                <div className="clientImgs">
                    <img src={mysql} alt="Client" className="clientImg" />
                    <img src={powerbi} alt="Client" className="clientImg" />
                    <img src={python} alt="Client" className="clientImg" />
                    <img src={excel} alt="Client" className="clientImg" />                   
                </div>
            </div>
            <div id="contact">
                <h1 className="contactPageTitle">Contact Me</h1>
                <span className="contactDesc">If you have any specific questions or need further assistance, feel free to ask!


<br/>



.</span>
                <br/>
                <span>NAME</span><span> </span><span>: </span><span> </span><span>BaLAKARTHIKEYAN</span>
                <br/>
                <br/>
                <span>EMAIL</span><span> </span><span>: </span><span> </span><span>sbb202122005@gmail.com</span>
                <br/>
                <br/>
               
                <strong>LINKDIN</strong> <span>: </span><strong><a href='https://www.linkedin.com/in/balakarthikeyan02122005'>balakarthikeyan02122005</a></strong>
                <br/>
                <br/>
                <strong>MOBILE-No</strong> <span>: </span><strong>8778725356</strong>

                <form className="contactForm" ref={form} onSubmit={sendEmail}>

                    {/* <div className="links">
                        <img src={facebookIcon} alt="Facebook" className="link" />
                        <img src={twitterIcon} alt="Twitter" className="link" />
                        <img src={youtubeIcon} alt="YouTube" className="link" />
                        <img src={instagramIcon} alt="Instagram" className="link" />
                    </div> */}
                </form>
            </div>
        </div>
    );
}

export default Contact;